.ant-form-item-label >label{
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
text-transform: uppercase;
width: 100%;
color: #0D062D;
 }
 .timeaddticket{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
margin-top: 100px;
color: #787486;
display: flex;
 }
 .textareafiledaddticket{
    width: 530px;
    height: fit-content;
 
    
    border: 1px solid #787486;
    border-radius: 6px;
 }
 .inputfiledaddticket{
    width: 530px;
    height: 60px;
 
    
    border: 1px solid #787486;
    border-radius: 6px;
 }
 .inputfiledaddticket {
    width: 530px;
    height: 60px;
 
    
    border: 1px solid #787486;
    border-radius: 6px;
 }
 .inputminifiledaddticket{
    width: 240px;
height: 60px;


border: 0.910839px solid #787486;
border-radius: 4px;
 }
 .ant-input:hover{
    width: 530px;
    height: 60px;
 
    
    border: 1px solid #787486;
    border-radius: 6px;
 }
 .ant-input:focus{
    width: 530px;
    height: 60px;
 
    
    border: 1px solid #787486;
    border-radius: 6px;
 }
 .ant-picker{
    border: 0.910839px solid #787486 !important;
border-radius: 4px !important;
width: 240px !important;
height: 60px !important;
 }
.css-dev-only-do-not-override-diro6f{
    max-width: 100% !important;
}
.ant-select-selector{
    box-sizing: border-box !important;

max-width: 530px !important;
min-width: 240px !important;

width: fit-content;
margin-left: 4px;
min-height: 60px !important;
height: fit-content !important;

border: none !important;
border-radius: none !important;
}
.selectProjectLeadgrand{
    box-sizing: border-box !important;

    width: 530px !important;
    min-height: 60px !important;
    height: fit-content !important;
    
    border: 0.910839px solid #787486 !important;
    border-radius: 4px !important;
}
.selectProjectLeadgranddisable{
    box-sizing: border-box !important;
    color: rgba(0, 0, 0, 0.25);
    background-color: rgba(0, 0, 0, 0.04);
    border-color: #d9d9d9 !important;
    width: 530px !important;
    min-height: 60px !important;
    height: fit-content !important;
    
    
    border-radius: 4px !important;
}
.selectProjectLead{
    box-sizing: border-box !important;

    width: 240px !important;
    margin-right: 28px;
    min-height: 60px !important;
    height: fit-content !important;    
    
    border: 0.910839px solid #787486 !important;
    border-radius: 4px !important;
}
.selectProjectLeaddisable{
    box-sizing: border-box !important;
    color: rgba(0, 0, 0, 0.25);
    background-color: rgba(0, 0, 0, 0.04);
    border-color: #d9d9d9 !important;
    width: 240px !important;
    min-height: 60px !important;
    height: fit-content !important;    
    
    border-radius: 4px !important;
}
.ant-select-in-form-item{
    width: 160% !important;
    min-height: 60px !important;
    height: fit-content !important;   
    
}
.ant-select-selector input{
    margin-top: 50px !important;
}
.ant-modal {
    padding-bottom: 0px !important;
}
.buutonsaveaddticket{
    width: 180px;
height: 50px;
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
display: flex;
justify-content: center;
color: #FFFFFF;
align-items: center;
background: #245097;
cursor: pointer;
border-radius: 25px
}
.buutoncanceladdticket{
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 14.5734px;
line-height: 18px;
/* identical to box height */
margin-top: 20px;
text-transform: capitalize;
display: flex;
justify-content: center;
color: #0D062D;
width: 180px;
}
.attaachfile{
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 14.5734px;
line-height: 18px;
/* identical to box height */

text-transform: capitalize;

color: #787486;
}
.ql-toolbar.ql-snow {
    background: #F8F8F8 !important;
border-radius: 5px !important;
}
.ant-upload-list-item {
    display: flex;
    flex-direction: column !important;
    height: fit-content !important;
}
.ant-upload-list {
    display: none !important;
    line-height: 1.5714285714285714;
    flex-wrap: wrap !important;
    
}

.rc-color-picker-panel {
    border: 1px solid #ccc;
  }
  .rc-color-picker-panel-inner {
    border: none;
    box-shadow: none;
  }
  .rc-color-picker-panel-board-hsv {
    border-radius: 12px;
    outline: none;
  }
  .rc-color-picker-panel-board-value {
    border: none;
    border-radius: 12px;
  }
  .rc-color-picker-panel-board-saturation {
    border: none;
    border-radius: 12px;
  }
  .rc-color-picker-panel-ribbon {
    border-radius: 12px;
  }
  .rc-color-picker-panel-wrap-preview {
    border-radius: 12px;
  }
  .rc-color-picker-panel-preview span {
    border-radius: 12px;
  }
  .rc-color-picker-panel-preview input {
    border-radius: 12px;
  }
  .css-13cymwt-control{
    box-sizing: border-box !important;


    width: 240px !important;
    height: 60px !important;
    
    overflow-y: scroll;

    background-color: transparent;
    border: 0.910839px solid #787486 !important;
    border-radius: 4px !important;
  }
  .css-t3ipsp-control{
    box-sizing: border-box !important;

    width: 240px !important;
    height: 60px !important;
    
   overflow-y: scroll;
    border-radius: 4px !important;
  }
  .css-16xfy0z-control{
    width: 240px !important;
    height: 60px !important;
  }