.e-schedule {
    width: auto !important; 
    height: 667px;
    margin-top: 20px;
    margin-left: 55px !important;
    margin-right: 50px !important;
}
.e-work-cells{
  height: 100px !important;

}
.e-resource-cells {
  height: 100px !important;
}
.e-block-appointment{
    border-radius: 31.64px !important;
    width: 100%;
    height:35px !important;
    margin-top: 5px;
  }
  .e-subject{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
   display: flex;
   justify-content: center;
   align-items: center;
    height: 100%;
    /* Dark Primary */
    background: rgba(255, 165, 0, 0.28);
    color: #29343E;
  } 
   .e-schedule .e-block-appointment {
    position: relative;

  }
 
  .containercalndrier{
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  margin-top: 50px;
  border-radius: 15px;
  height: 110vh;
  

  }
  .profile-container{
    display: flex;
    flex-direction: column;
    padding: 5%;
    background: #FFFFFF;
    margin-top: 50px;
    border-radius: 15px;
  
    width: 90%;
  
    }
  .e-content-wrap table col { 
    width: 190px !important; 
} 
.e-date-header-wrap table tbody td {
    width: 190px !important; 
}
.devmemberco{
    width: 204px;
height: 46px;
display: flex;
justify-content: center;
align-items: center;
text-align: center;
font-family: 'Poppins';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 18px;
/* identical to box height */
border: none;

/* Dark Primary */

color: #29343E;
flex: none;
order: 0;
flex-grow: 0;

background: rgba(122, 148, 190, 0.1);
border-radius: 50px;
}
option {
  display: flex !important;
  justify-content: center !important;
}
.file-input-container input[type="file"] {
  display: none;
  margin: 2%;

}
.file-input-container{
margin: 2%;
}
.file-input-container label {
  background-color: #245097;
  border: none;
  color: white;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
}
.file-input-container button{
  background-color: #245097;
  border: none;
  color: white;
  padding: 10px;
  text-align: center;
  text-decoration: none;
  display: flex;
  justify-content: center;
  display: inline-block;
  font-size: 16px;
}