.ant-table-tbody::-webkit-scrollbar {
    display: none;
  } 
  .container {
    padding: 20px 60px;
  }
  
  .header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .title {
    margin-right: auto;
  }
  
  .action {
    padding-left: 20px;
  }
  

  .containerprogress{
    width: 100%;
    height: 100px;
    position: relative;
}
.box{
    width: 100%;
    height: 100%;            
    position: absolute;
    top: -10px;
    left: 0;
    opacity: 0.8;  /* for demo purpose  */
}
.stack-top{
    z-index: 9;
    margin: 20px; /* for demo purpose  */
}
.ant-table-cell{
 text-align: center !important;
 background: #FFFFFF !important;

 
}

.ant-dropdown-link {
    color: #1890ff;
    font-weight: 600;
    display: inline-block;
    padding: 8px;
    border-radius: 4px;
    transition: all 0.3s ease;
  
    
  }
  .ant-dropdown-link:hover{

        color: #40a9ff;
        background-color: #f5f5f5;
      
  }
  
  .ant-table-body::-webkit-scrollbar {
    display: none;  /* Safari and Chrome browsers */
}
.ant-pagination-options{display: none !important;}