 /* HTML and style credits: https://codepen.io/Paolo-Duzioni/pen/WNrJdYd */

 @import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.1/css/all.min.css");

 :root {
   --app-col: #f5f6f8;
 }
 body {
   font-family: Lato, sans-serif;
   font-size: 16px;
 }
 .trello_page {
   display: grid;
   grid-template-rows: max-content auto;
   grid-template-columns: 1fr 11fr;
   min-height: 100vh;
   background: #E1E1E1;
 }
 
 .btn {
   padding: 0.5rem;
   color: #fff;
   background: rgba(255, 255, 255, 0.5);
   border-radius: 4px;
 }
 .btn.btn-text i {
   margin-right: 0.25rem;
 }
 
 
 
 .app-content-area {
 }
 .app-header {
   display: flex;
   grid-template-columns: 1fr 1fr 1fr;
   align-items: center;
   justify-content: space-between;
   padding: 0rem 1rem;
   
 }
 .app-header1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  padding: 0rem 1rem;
  justify-items: center;
  margin-top: -400px;
}
 .app-header .left,
 .app-header .right {
   display: flex;
 }
 .app-header .left {
   grid-template-columns: repeat(3, max-content);
 }
 .app-header .semileft {
  grid-template-columns: repeat(2, max-content);
}
 .app-header .center {
   text-align: center;
 }
 .app-header .right {
  justify-content: flex-end;
  width: 100%;
 }
 .app-header .logo {
    background: #FFFFFF;
    border-radius: 4px;
    width: 60%;
    height: 50px;
    display: flex;
    flex-direction: row;
   filter: drop-shadow(11px 5px 13px rgba(0, 0, 0, 0.09)) drop-shadow(3px 1px 7px rgba(0, 0, 0, 0.1)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.1));
 }
 .app-header .demilogo {
  background: #FFFFFF;
  border-radius: 4px;
  width: 50%;
  height: 50px;
  display: flex;
  flex-direction: row;
 filter: drop-shadow(11px 5px 13px rgba(0, 0, 0, 0.09)) drop-shadow(3px 1px 7px rgba(0, 0, 0, 0.1)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.1));
}
 .app-header .semilogo {
  background: #FFFFFF;
  border-radius: 4px;
  width: 60%;
  height: 50px;
  display: flex;
  flex-direction: row;
 filter: drop-shadow(11px 5px 13px rgba(0, 0, 0, 0.09)) drop-shadow(3px 1px 7px rgba(0, 0, 0, 0.1)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.1));
}
.app-header .grandsemilogo {
  background: #FFFFFF;
  border-radius: 4px;
  width: 91%;
  height: 50px;
  display: flex;
  flex-direction: row;
 filter: drop-shadow(11px 5px 13px rgba(0, 0, 0, 0.09)) drop-shadow(3px 1px 7px rgba(0, 0, 0, 0.1)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.1));
}
.app-header .minilogo {
  background: #FFFFFF;
  border-radius: 4px;
  width: 20%;
  height: 50px;
  display: flex;
  flex-direction: row;
 filter: drop-shadow(11px 5px 13px rgba(0, 0, 0, 0.09)) drop-shadow(3px 1px 7px rgba(0, 0, 0, 0.1)) drop-shadow(0px 0px 0px rgba(0, 0, 0, 0.1));
}


 .app-header .logo1 {
    
    display: flex;
    flex-direction: row;
 }
 .soustitreboard{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    margin-top: 24px;
    
    color: #323338;
    
    
    /* Inside auto layout */
    
    flex: none;
    order: 0;
   
    flex-grow: 0;
 }
 .Archivetextboard{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  
  color: #245097;
  
  cursor: pointer;
  /* Inside auto layout */
  
  flex: none;
  order: 0;
  flex-grow: 0;
}
 .soustitreboardActive{

/* identical to box height */

font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    margin-top: 24px;
    
    color: #245097;
    
    margin-left: 10px;
    /* Inside auto layout */
    
    flex: none;
    order: 0;
    flex-grow: 0;

 }
 .app-header .demilogo .select{
  width: 33%;
  height: 50px;
  left: 399px;
  top: 21px;
  display: flex; 
  justify-content: center;
  cursor: pointer;
  align-items: center;
  background: #fff;
  border-radius: 4px;
}
 .app-header .logo .select{
    width: 33.33%;
    height: 50px;
    display: flex; 
    justify-content: center;
    cursor: pointer;
    align-items: center;
    background: #fff;
    border-radius: 4px;
 }
 .app-header .demilogo .select1{
  width: 33%;
  height: 50px;
  left: 399px;
  top: 21px;
  display: flex; 
  justify-content: center;
  cursor: pointer;
  align-items: center;
  background: #fff;
  border-radius: 4px;
}
 .app-header .logo .select1{
    width: 33.33%;
    height: 50px;
    left: 399px;
    top: 21px;
    display: flex; 
    cursor: pointer;

    justify-content: center;
    align-items: center;
    background: rgba(36, 80, 151, 0.1);
    border-radius: 4px;
 }
 .app-header .minilogo .miniselect1{
  width: 100%;
  height: 50px;
  left: 399px;
  top: 21px;
  display: flex; 
  cursor: pointer;
  justify-content: center;
  align-items: center;
  background: rgba(36, 80, 151, 0.1);
  border-radius: 4px;
}
 .app-header .logo .miniselect1{
  width: 100%;
  height: 50px;
  left: 399px;
  top: 21px;
  display: flex; 
  cursor: pointer;
  justify-content: center;
  align-items: center;
  background: rgba(36, 80, 151, 0.1);
  border-radius: 4px;
}
 .app-header .logo i {
   font-style: normal;
 }
 .app-header .search {
   display: flex;
   justify-content: center;
 
   background-color: #ebecf0;
   width: 30%;
   height: 50px;

   
   background: #245097;
   border-radius: 4px;
   padding: 0.8rem;
 }
 .AddTask{
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
display: flex;
align-items: center;
color: #FFFFFF;
margin-left: 10px;
 }
 .app-board {
   display: grid;
   grid-template-rows: max-content auto;
 }
 .app-board1 {
  display: grid;
  grid-template-rows: max-content auto;


}
 .app-board .board-header {
   display: grid;
   grid-template-columns: repeat(2, auto);
   padding: 0.5rem;
 }
 .app-board .board-header .left,
 .app-board .board-header .right {
   display: grid;
   grid-gap: 7px;
   align-items: center;
 }
 .app-board .board-header .left .btn,
 .app-board .board-header .right .btn {
   color: var(--app-col);
 }
 .app-board .board-header .left {
   grid-template-columns: repeat(4, max-content);
 }
 .app-board .board-header .right {
   justify-content: end;
   grid-template-columns: repeat(2, max-content);
 }
 .app-board .board-header .title {
   font-weight: 700;
   font-size: 1.25em;
 }
 .app-board .board-body {
   
   overflow-y: hidden;
   height: 130vh;
 }
 .app-board .board-body1 {
  overflow-y: hidden;
  height: fit-content;
}
 .app-board .board-body .wrap-lists {
   display: grid;
   grid-auto-columns: 33%;
   grid-auto-flow: column;
   height: calc(100% - 16px);
   padding-bottom: 0.5rem;
   overflow-y: auto;

 }
 .wrap-lists::-webkit-scrollbar {
  display: none;  /* Safari and Chrome browsers */
}
 .app-board .board-body .list {
   display: grid;
   grid-auto-rows: max-content;
   grid-gap: 10px;
   padding: 0.5rem;
   font-size: 0.875em;
   /* background: rgba(255, 255, 255, 0.5); */
   border-radius: 8px;
 }
 .textprojectname{
    font-family: 'Inter';
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 15px;
/* identical to box height */
width: 30%;
display: flex;
color: #F9F9F9;
 }
 .texttaskname{
  font-family: 'Inter';
font-style: normal;
font-weight: 600;
max-width: 90%;
font-size: 12px;
line-height: 15px;
/* identical to box height */
width: 90%;
display: flex;
color: #F9F9F9;
}
 .app-board .board-body .list .list-title {

   margin: 0;
 }
 .app-board .board-body .list .boardContentArea {
 
   height: 73vh;
   overflow-y: scroll;
 }
 .app-board .board-body .list .boardContentArea::-webkit-scrollbar {
  display: none;
}
 .app-board .board-body .list .boardContentArea.hovered {
   padding-bottom: 15px;
 }
 .app-board .board-body .list .card1 {
  padding: 1rem 1.2rem;
  border-radius: 10px;
  width: 100%;

  min-height: 200px;
  height: fit-content;
margin-top: 25px;
cursor: grab;
background: linear-gradient(to right,  #FF0B00 2%, #ffffff 2%);


  box-shadow: 0px 10px 17px -10px rgba(0, 0, 0, 0.59);
  margin-bottom: 10px;
  position: relative;
 }
 .app-board .board-body .list .card2 {
  padding: 1rem 1.2rem;
  border-radius: 10px;
  width: 100%;

  min-height: 200px;
  height: fit-content;
margin-top: 25px;
cursor: grab;
background: linear-gradient(to right,  #FFA500 2%, #ffffff 2%);


  box-shadow: 0px 10px 17px -10px rgba(0, 0, 0, 0.59);
  margin-bottom: 10px;
  position: relative;
}
.app-board .board-body .list .card3 {
  padding: 1rem 1.2rem;
  border-radius: 10px;
  width: 100%;

  min-height: 200px;
  height: fit-content;
margin-top: 25px;
cursor: grab;
background: linear-gradient(to right,  #68B266 2%, #ffffff 2%);


  box-shadow: 0px 10px 17px -10px rgba(0, 0, 0, 0.59);
  margin-bottom: 10px;
  position: relative;
}

 .app-board .board-body .list .card1.hidden {
   display: none;
 }
 .app-board .board-body .list .card1.ohhold {
   border: 2px solid #fff;
   transform: rotateZ(-2deg);

 }
 .app-board .board-body .list .card1.anotherCardOnTop {
   margin-top: 5px;
 }
 ---color2--
 .app-board .board-body .list .card2 .hidden {
  display: none;
}
.app-board .board-body .list .card2.ohhold {
  border: 2px solid #fff;
  transform: rotateZ(-2deg);

}
.app-board .board-body .list .card2.anotherCardOnTop {
  margin-top: 5px;
}
---color3
.app-board .board-body .list .card3 .hidden {
  display: none;
}
.app-board .board-body .list .card3.ohhold {
  border: 2px solid #fff;
  transform: rotateZ(-2deg);

}
.app-board .board-body .list .card3.anotherCardOnTop {
  margin-top: 5px;
}
 .ant-dropdown-trigger{
    border: none !important;
    background-color: transparent !important;
    color: black;
 }
 .app-board .board-body .list .card1 .cardTitle {
 
   font-family: 'Inter';
font-style: normal;
font-weight: 300;
font-size: 12px;
line-height: 15px;
/* identical to box height */

color: #787486;

 }

 .app-board .board-body .list .card1 .cardTitle::-webkit-scrollbar {
  display: none;  /* Safari and Chrome browsers */
}
 .app-board .board-body .list .card1 .cardFooter {
   display: flex;
   flex-direction: column;
 }
 .app-board .board-body .list .card1 .cardFooter .label {
   font-size: 0.7rem;
   padding: 0.2rem 0.5rem;
   background-color: rgba(0, 0, 0, 0.1);
   color: rgba(0, 0, 0, 1);
   border-radius: 0.8rem;
 }
 .app-board .board-body .list .card1 .cardFooter .label.color1 {
   background-color: #ff00001a;
   display: flex;
   color: #ff0000;
 }
 ---color2---
 .app-board .board-body .list .card2 .cardTitle {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */

  color: #787486;

}
.app-board .board-body .list .card2 .cardTitle::-webkit-scrollbar {
 display: none;  /* Safari and Chrome browsers */
}
.app-board .board-body .list .card2 .cardFooter {
  display: flex;
  flex-direction: column;
}
.app-board .board-body .list .card2 .cardFooter .label {
  font-size: 0.7rem;
  padding: 0.2rem 0.5rem;
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 1);
  border-radius: 0.8rem;
}
.app-board .board-body .list .card2 .cardFooter .label.color1 {
  background-color: #ff00001a;
  display: flex;
  color: #ff0000;
}
---color3---
.app-board .board-body .list .card3 .cardTitle {
 
  font-family: 'Inter';
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 15px;
  /* identical to box height */


  color: #787486;

}
.app-board .board-body .list .card3 .cardTitle::-webkit-scrollbar {
 display: none;  /* Safari and Chrome browsers */
}
.app-board .board-body .list .card3 .cardFooter {
  display: flex;
  flex-direction: column;
}
.app-board .board-body .list .card3 .cardFooter .label {
  font-size: 0.7rem;
  padding: 0.2rem 0.5rem;
  background-color: rgba(0, 0, 0, 0.1);
  color: rgba(0, 0, 0, 1);
  border-radius: 0.8rem;
}
.app-board .board-body .list .card3 .cardFooter .label.color1 {
  background-color: #ff00001a;
  display: flex;
  color: #ff0000;
}
.textby {
  display: flex;
  color: rgba(0, 0, 0, 1);
}
 .textTasks{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
margin-left: 20px;


color: #787486;
 }
 .textTasksActive{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 19px;
margin-left: 20px;
color: #245097;
 }
 .app-board .board-body .list .card1 .cardFooter .label.color2 {
   background-color: rgba(255, 165, 0, 0.15);;
   display: flex;
   color: #FFA500;
 }
 .app-board .board-body .list .card1 .cardFooter .label.color3 {
   background-color: rgba(131, 194, 157, 0.2);
   display: flex;
   color: #68B266;
 }
 .app-board .board-body .list .card1 .cardFooter .collab {
   display: flex;
   margin-top: 23px;
   justify-content: center;
   width: 100%;
 }
 .app-board .board-body .list .card1 .cardFooter .collab .collabPerson {
   display: block;
   width: 30%;
   height: 30px;
   gap: 10px;
   border-radius: 50%;
   background-color: #ccc;
   display: flex;
   justify-content: center;
   align-items: center;
 }
 .app-board .board-body .list .card1 .cardFooter .collab .collabPerson1 {
    display: block;
    width: 70%;
    height: 30px;
    gap: 2px;
    border-radius: 50%;
    display: flex;
    justify-content: start;
    align-items: center;
  }
 ---color2----

  .app-board .board-body .list .card2 .cardFooter .label.color2 {
    background-color: rgba(255, 165, 0, 0.15);;
    display: flex;
    color: #FFA500;
  }
  .app-board .board-body .list .card2 .cardFooter .label.color3 {
    background-color: rgba(131, 194, 157, 0.2);
    display: flex;
    color: #68B266;
  }
  .app-board .board-body .list .card2 .cardFooter .collab {
    display: flex;
    margin-top: 23px;
    justify-content: center;
    width: 100%;
  }
  .app-board .board-body .list .card2 .cardFooter .collab .collabPerson {
    display: block;
    width: 30%;
    height: 30px;
    gap: 10px;
    border-radius: 50%;
    background-color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .app-board .board-body .list .card2 .cardFooter .collab .collabPerson1 {
     display: block;
     width: 70%;
     height: 30px;
     gap: 2px;
     border-radius: 50%;
     display: flex;
     justify-content: start;
     align-items: center;
   }
  ---color3---
  .app-board .board-body .list .card3 .cardFooter .label.color2 {
    background-color: rgba(255, 165, 0, 0.15);;
    display: flex;
    color: #FFA500;
  }
  .app-board .board-body .list .card3 .cardFooter .label.color3 {
    background-color: rgba(131, 194, 157, 0.2);
    display: flex;
    color: #68B266;
  }
  .app-board .board-body .list .card3 .cardFooter .collab {
    display: flex;
    margin-top: 23px;
    justify-content: center;
    width: 100%;
  }
  .app-board .board-body .list .card3 .cardFooter .collab .collabPerson {
    display: block;
    width: 30%;
    height: 30px;
    gap: 10px;
    border-radius: 50%;
    background-color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .app-board .board-body .list .card3 .cardFooter .collab .collabPerson1 {
     display: block;
     width: 70%;
     height: 30px;
     gap: 2px;
     border-radius: 50%;
     display: flex;
     justify-content: start;
     align-items: center;
   }
 .app-board .board-body .list .btn-list {
   text-decoration: none;
   color: rgba(0, 0, 0, 0.5);
   display: flex;
   cursor: default;
    justify-content: center;
 }
 .app-tint {
   position: fixed;
   bottom: 1rem;
   right: 1rem;
 }
 .app-tint input[type="color"] {
   position: absolute;
   top: 0;
   left: 0;
   z-index: -1;
   opacity: 0;
 }
 .app-tint label {
   font-size: 1.25em;
   color: var(--app-col);
   cursor: pointer;
 }
 .titleboard{
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 14.9314px;
line-height: 18px;
margin-left: 8px;
/* identical to box height */


color: #0D062D;
 }
 
 .textcarfooter{
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 11.1985px;
line-height: 14px;
/* identical to box height */

width: 82px;
color: #787486;
 }
 .text-more-task{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 15px;

color: #000000;
 }
 .ListProject{
  display: flex;
  grid-auto-columns: 10%;
  grid-auto-flow: column;
  grid-gap: 5%;
  height: 100%;
  padding-bottom: 0.5rem;
  overflow-y: auto;
  flex-wrap: wrap;

}
.ListProject::-webkit-scrollbar {
  display: none;
}
