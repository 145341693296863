.NameSidBar{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    
    color: #0D062D;
}
.NameSettingSidBar{
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  justify-content: center;
  margin-top: 5%;
  color: #0D062D;
}
.DepartementSidBar{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    
    
    color: #787486;
}
.TiltleNumberprojectSidBar{
    font-family: 'Inter';
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 19px;

color: #787486;

}
.numbersidbar{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 36px;
    margin-left: 2rem;
    color: #0D062D;
}
.TitleRequest{
    font-family: 'Inter';
font-style: normal;
font-weight: 700;
font-size: 16px;
line-height: 19px;
margin-left: 40px;
color: #0D062D;
}
.dateRequest{
    font-family: 'Inter';
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 19px;
margin-right: 16px;

color: #787486;
}
.ant-steps-item-title {
    position: absolute !important;
    left: -79px !important;
    font-family: 'Inter';
font-style: normal;
font-weight: 300;
width: 60px;
font-size: 16px;
line-height: 19px;

color: #323338;
}
.ant-steps-item-subtitle {
    display: block !important;
    margin-inline-start:"0px !important";
}
.buttonLoadrequest{
    background: #245097;
border-radius: 4px;
width: 50%;
height: 50px;
display: flex;
cursor: pointer;
justify-content: center;
align-items: center;
font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 19px;
padding: 0.8rem;
/* identical to box height */

border: none;
color: #F9F9F9;
}
.ant-dropdown-menu::-webkit-scrollbar {
    display: none;
  }

  .ant-steps-icon  {
    font-family: 'Inter' !important;
font-style: normal !important;
font-weight: 900 !important;
font-size: 12.8px !important;
line-height: 15px !important;
color: #F9F9F9 !important;
  }
  .ant-steps-item-icon{
    background-color: #787486 !important;
    border: none !important;
  }
   .ant-steps-item-title::after {
    background: transparent !important;
}
.icon-tag {
    position: absolute;

    top: 33px;
    right: 55px;
    z-index: 1;
   
  }
  .block-icon {
   
    display: flex;
  }
  .icon-tag1 {
   display: none;
   
  }
  .notifications {
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    color: black;
    font-weight: 300;
    display: flex;
    flex-direction: column;
    padding: 10px;
  }
  .contantrequest{
    display: flex;
              justify-content: center;
              margin-top: 32px;
              margin-left: 20%;
     height: 478px;
     overflow:scroll;
     -ms-overflow-style: "none";
     scrollbar-width: none;
  }
  .contantrequest::-webkit-scrollbar {
    display: none;  /* Safari and Chrome browsers */
}
.image-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-image {
  width: 100px; /* Set the desired width for the dropdown image */
  height: auto;
  cursor: pointer;
}

.options-container {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
}

.option {
  display: block;
  width: 100px; /* Set the desired width for the option images */
  height: auto;
  cursor: pointer;
  margin-bottom: 5px;
}

.image-dropdown:hover .options-container {
  display: block;
}