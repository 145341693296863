 .ant-modal-content {
    width: 600px !important;
min-height: 1024px !important;
height: fit-content !important;
background: #FFFFFF !important;
border-radius: 10px 0px 0px 10px !important;
 }
 .ant-modal-mask{
  background-color: rgba(0, 0, 0, 0.10) !important;
 }
 .titleAddTicket{
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 36px;
line-height: 44px;
margin-top: 84px;
/* identical to box height */


color: #0D062D;
 }
.Teammodaltextrole{
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;
/* identical to box height */


color: #000000;
}
.Teammodaltextrole{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 25px;
    line-height: 12px;
    /* identical to box height, or 48% */
    
    
    color: #8991A0;
}
.modal-body{
    margin-bottom: 20px !important;
}

 .darkBG {
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 240vh;
    z-index: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
 
  .centered {
    position: fixed;
    top: 50%;
    left: 60%;
    width: 60%;
    height: 90%;
    display: flex;
    align-items: center;
    z-index: 1;
    background: #FFFFFF;
    border-radius: 15px;
    transform: translate(-50%, -50%);
    
  }
  .contenttittle{
    display:flex;justify-content:center;width:90%;flex-direction:column;margin-left:5%;padding-top:3%;
  }
   
  .modalRecurtement {
    width: 250px;
    height: 170px;
    background: white;
    color: white;
    z-index: 10;
    border-radius: 16px;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  }
 
  .modalHeader {
    height: 50px;
    background: white;
    overflow: hidden;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  
  .heading {
    margin: 0;
    padding: 10px;
    color: #2c3e50;
    font-weight: 500;
    font-size: 18px;
    text-align: center;
  }
  
  .modalContent {
    padding: 10px;
    font-size: 14px;
    color: #2c3e50;
    text-align: center;
  }
  
  .modalActions {
    position: absolute;
    bottom: 2px;
    margin-bottom: 10px;
    width: 100%;
  }
  
  .actionsContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }
  
  .closeBtn {
    cursor: pointer;
    font-weight: 500;
    padding: 4px 8px;
    border-radius: 8px;
    border: none;
    font-size: 18px;
    color: #2c3e50;
    background: white;
    transition: all 0.25s ease;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
    position: absolute;
    right: 0;
    top: 0;
    align-self: flex-end;
    margin-top: -7px;
    margin-right: -7px;
  }
  
  .closeBtn:hover {
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
    transform: translate(-4px, 4px);
  }
  
  .deleteBtn {
    margin-top: 10px;
    cursor: pointer;
    font-weight: 500;
    padding: 11px 28px;
    border-radius: 12px;
    font-size: 0.8rem;
    border: none;
    color: #fff;
    background: #ff3e4e;
    transition: all 0.25s ease;
  }
  
  .deleteBtn:hover {
    box-shadow: 0 10px 20px -10px rgba(255, 62, 78, 0.6);
    transform: translateY(-5px);
    background: #ff3e4e;
  }
  
  .cancelBtn {
    margin-top: 10px;
    cursor: pointer;
    font-weight: 500;
    padding: 11px 28px;
    border-radius: 12px;
    font-size: 0.8rem;
    border: none;
    color: #2c3e50;
    background: #fcfcfc;
    transition: all 0.25s ease;
  }
  
  .cancelBtn:hover {
    box-shadow: none;
    transform: none;
    background: whitesmoke;
  } 
  .inputfiledjob{
    width: 100%;
    height: 60px;
 
    display: flex;
    border: 1px solid #787486;
    border-radius: 6px;
  }
  .input_copy_wrapper{

    height: 100%;
 
    display: flex;
    border: 1px solid #787486;
    border-radius: 6px;
    margin : 0 auto;
  }
  .input_copy {
      padding: 15px 25px;
      width: 100%;
      background: #eee;
      border: 2px solid #aaa;
      color: #aaa;
      font-size: .8em;
  }
  
  .input_copy .icon {
      display: block;
      max-width: 25px;
      cursor: pointer;
      float: right;
  }
  
  .input_copy .icon img{
    max-width: 25px;
  }
  .input_copy .txt {
      width: 80%;
      display: inline-block;
      overflow: hidden;
  }
  
  
  /* click animation */
  
  .flashBG {
      animation-name: flash;
      animation-timing-function: ease-out;
      animation-duration: 1s;
  }
  
  @keyframes flash {
      0% {
          background: #28a745;
      }
      100% {
          background: transparent;
      }
  }
  
  
  .selectProjectLeadjob{
    box-sizing: border-box !important;

    width: 100% !important;
    height: 60px !important;
    
    
    /* border: 0.910839px solid #787486 !important; */
    border-radius: 4px !important;
  }
  .titlePostForm{
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 36px;
line-height: 44px;

color: #0D062D;
  }
  @media screen and (ax-width: 900px){
    
  }