.ql-snow.ql-toolbar .buttonsend{

padding: 6px 14px;
gap: 10px;

width: 67px;
height: 31px;

font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 19px;
letter-spacing: -0.01em;

color: #FFFFFF;

/* Gray 4 */

background: #BDBDBD;
border-radius: 4px;

}
.ql-snow.ql-toolbar .buttonsend:hover{

    padding: 6px 14px;
    gap: 10px;
    
    width: 67px;
    height: 31px;
    
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.01em;
    
    color: #FFFFFF;
    
    /* Gray 4 */
    
    background: #BDBDBD;
    border-radius: 4px;
    
    }
.ql-editor{
    font-family: 'Inter' !important;
font-style: normal !important;
font-weight: 400 !important;
font-size: 22px !important;
line-height: 27px !important;
/* identical to box height */

letter-spacing: -0.01em !important;

color: black !important;
}
.ql-editor::before{
    font-family: 'Inter' !important;
font-style: normal !important;
font-weight: 400 !important;
font-size: 22px !important;
line-height: 27px !important;
/* identical to box height */

letter-spacing: -0.01em !important;

color: #9E9EA8 !important;
}
.textcomment{
    font-family: 'Inter';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 30px;
/* or 150% */


color: #272727;
}
.nametextcomment{
    font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;
/* identical to box height */


color: #000000;

}
.inputcomment{
    display: flex;
    flex-wrap: wrap;
    background: #FFFFFF;
border: 1.5px solid rgba(180, 187, 198, 0.5);
border-radius: 7px;
}
.toolbar{
    width: 100%;
    border: none;
    height:46px;
}
.buttonsend{

    padding: 6px 14px;
    gap: 10px;
    
    width: 67px;
    height: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.01em;
    border: none;
    color: #FFFFFF;
    
    /* Gray 4 */
    
    background: #BDBDBD;
    border-radius: 4px;
}    
.buttonsend:hover{

    padding: 6px 14px;
    gap: 10px;
    
    width: 67px;
    height: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: -0.01em;
    
    color: #FFFFFF;
    
    /* Gray 4 */
    
    background: #BDBDBD;
    border-radius: 4px;
    
    }