.cardsatts{
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    min-width: 0px;
    overflow-wrap: break-word;
    border-radius: 20px;
    background: border-box rgb(255, 255, 255);
}
.containstats{
    margin-inline: auto;
    padding: 50px 20px 20px;
    min-height: 100vh;
}
.containerLinechart{
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    min-width: 0px;
    overflow-wrap: break-word;
    -webkit-box-pack: center;
    justify-content: center;
    margin-bottom: 0px;
    padding: 35px;
    border-radius: 17.889px;
    border: 0.894px solid var(--stroke-color, #EFF0F6);
    background: #FFF;
}
.minicardstast{
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    min-width: 0px;
    overflow-wrap: break-word;
    padding: 15px 20px;
    border-radius: 20px;
    background: border-box rgb(255, 255, 255);
}
.sousminicardstast{
    display: flex;
    /* -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center; */
    margin-top: auto;
    margin-bottom: auto;
    height: 100%;
    -webkit-box-align: start;
    align-items: start;
    -webkit-box-pack: center;
    justify-content: center;
}
.cardtraffics{
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    min-width: 0px;
    overflow-wrap: break-word;
    padding: 20px;
    border-radius: 20px;
    background: border-box rgb(255, 255, 255);
}