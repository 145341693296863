.formBackground{
    width:100%;
    min-height:74%;
    height: 74%;
      margin-left: 10%;
    margin-right: -2%;
    padding-right: 0%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
box-shadow: 0px 10px 20px 10px rgba(0, 0, 0, 0.25);
border-top-left-radius: 52px;
border-bottom-left-radius: 52px;
background: #FFFFFF;
}
.formBackground1{
    width:100%;
    min-height:74%;
    height: 74%;
    margin-left: -1%;
    margin-right: 10%;
    padding-right: 0%;
    display: flex;
    
    flex-wrap: wrap;
box-shadow: 35px 10px 20px 10px rgba(0, 0, 0, 0.25);
border-top-right-radius: 52px;
border-bottom-right-radius: 52px;
background: #245097;
}
.loginBackground{

    height: 100vh;
    display: flex;
    align-items: center;

     background-image: url('../../assets/background4.png');
}
.loginBackground1{
 height: 100vh;
   display: flex;
align-items: center;
     background-image: url('../../assets/bg1.png');
}


.titrelogin{
    font-family: 'Inter';
font-style: normal;
font-weight: 500;
font-size: 35px;
line-height: 56px;
/* or 160% */
display: flex;
align-items: center;
letter-spacing: 0.05em;

color: #FFFFFF;
text-align: start;
}
.titre2login{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 36px;
    margin-top: 5%;
    color: #FFFFFF;
    text-align: start;
}
.labelInputlogin{
    font-family: 'Inter';
font-style: normal;
text-align: start;
margin-top: 10px;

font-weight: 500;
font-size: 16px;
line-height: 19px;
text-transform: uppercase;

color: #FFFFFF;
}
.inputlogin{
    margin-top: 10px;

    border: 1px solid #FFFFFF !important;;
    border-radius: 6px !important;
background-color: transparent !important;
width: 100% !important;
margin-top: 3% !important;
height: 50px !important;
font-family: 'Inter';
font-style: normal;
font-weight: 300;
font-size: 15px;
line-height: 18px;

color: #FFFFFF;
}
.inputlogin::placeholder {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-size: 15px;
    line-height: 18px;
    
    color: #FFFFFF;
margin-left: 120px !important;
}
.textlogin{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
    width: 119px;
    letter-spacing: 0.3px;
    text-transform: uppercase;
    
    color: #5F5F5F;
}
.buttonsiginin{
    width: 100%;
margin-top: 3%;
cursor: pointer;
border: none;
height: 50px;
background: #FFFFFF;
border-radius: 15px;
font-family: 'Roboto';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 28px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.15em;
text-transform: uppercase;
display: flex;
justify-content: center;
align-items: center;
color: #245097;

}
.buttonsigininwithgoogle{
    width: 74%;
    margin-top: 3%;
    height: 50px;
    cursor: pointer;

    background: #233142;
    border: 1px solid rgba(255, 255, 255, 0.8);
    border-radius: 15px;  
    font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 20px;
line-height: 23px;
display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.05em;
display: flex;
justify-content: center;
color: #FFFFFF;
}
.textforgetpassword{
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-align: right;
    letter-spacing: 0.05em;
    text-decoration-line: underline;
    
    color: #FFFFFF;
display: flex;
align-items: center;

   margin-right: 0px;

   
    width: 100%;
    margin-top: 5%;

    justify-content: flex-end;
}



 
.user-circle {
   position: absolute;
  width: 60%;
  height: 60%;
  border-radius: 50%;
  background: rgba(36, 80, 151, 0.04);
border: 3px dashed #245097;
  }
  img:nth-child(1) {
    top: 10%;
    left: -7%;
  }
  img:nth-child(2) {
    top: 60%;
    left: -8%;
  }
   img:nth-child(3) {
    top: 10%;
    left: -8%;
  }
  img:nth-child(4) {
    top: 60%;
    left: 78%;
  }
  img:nth-child(5) {
    top: 10%;
    left: 78%;
  } 
  img:nth-child(6) {
    top: 80%;
    left: 37%;
   
  } 
  img:nth-child(7) {
    top: -10%;
    left: 37%;
   
  } 
