.ant-badge-count{
    position: absolute !important;
    top: 0px !important;
    inset-inline-start: 0px !important;
    transform: translate(0%, 100%) !important;
    transform-origin: 100% 0% !important;
}
.ant-badge-count{


width: fit-content!important;
max-width: 80%;
height: 20px!important;

left:20px !important;
border-radius: 4px!important;

}
.text-more-task{
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    
    color: #000000;
      
}
.label.color1 {
    background-color: #ff00001a;
    display: flex;
    color: #ff0000;
  }
  .label {
    font-size: 0.7rem;
    padding: 0.2rem 0.5rem;
    background-color: rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 1);
    border-radius: 0.8rem;
  }