.cardproject{
 
width:   100%;
height: 200px;

margin-top: 30px;
background: #FFFFFF;
border-radius: 10px;
}
.cardTitle {
 
    font-family: 'Inter';
 font-style: normal;
 font-weight: 300;
 font-size: 12px;
 line-height: 15px;
 /* identical to box height */
 margin-top: 35px;
 
 color: #787486;
 
  }
  .cardProjectFooter {
    display: flex;
    flex-direction: column;
  }
  .containaircardproject {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 55px;
    align-items: flex-end;
  }
  .cardProjectPerson {
    display: block;
    width: 30px;
    height: 30px;
    gap: 10px;
    border-radius: 50%;
    background-color: #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;

  }
  .cardProjectPerson1 {
    display: block;
    width: 150px;
    height: 30px;
    margin-left: -65px;
    gap: 2px;
    border-radius: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .textcardpersonfooter{
    font-family: 'Inter';
font-style: normal;
font-weight: 300;
font-size: 11px;
line-height: 13px;
margin-top: 10px;
margin-left: 10px;
color: #787486;
  }
  .descriptionproject{
   width: 80%; 
   overflow-y: scroll;
   font-family: 'Inter';
   font-style: normal;
   font-weight: 300;
   font-size: 12px;
   height: 85px;
   margin-top: 35px;
   line-height: 15px;
   /* identical to box height */
   margin-left: 20px;
   color: #787486;
  }
  .descriptionproject::-webkit-scrollbar{
display: none;
   }
